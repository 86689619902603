/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

export default function ProjectItem({ name, link, desc, tags }) {
    const [linkURL, linkText] = processLink(link);
    return (
        <div>
            <h2 sx={{ marginBottom: '2px' }}>{name}</h2>
            <a href={linkURL}>{linkText}</a>
            <div>
                Keywords: {tags}
            </div>
            <div sx={{ color: 'CaptionText' }}>{desc}</div>
        </div>
    );
}

function processLink(input = '') {
    if (input.startsWith('gh:')) {
        input = input.replace('gh:', '');
        if (input.indexOf('/') === -1) {
            return ['https://github.com/yifeiyin/' + input, 'GitHub'];
        } else {
            return ['https://github.com/' + input, 'GitHub'];
        }
    } else if (input.startsWith('http://') || input.startsWith('https://')) {
        const [link, ...rest] = input.split(' ');
        return [link, rest.join(' ')];
    } else {
        throw new Error('Unknown link format');
    }
}

