/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

function SkillContainer({ children }) {
    return (
        <div sx={{ display: 'grid', justifyContent: 'start', alignItems: 'center', columnGap: '10px', marginBottom: 20 }}>
            {children}
        </div>
    );
}

function SkillSection({ title, children }) {
    return (
        <>
            <h3 sx={{
                gridColumnStart: 1,
                justifySelf: [null, null, 'end'],
            }}>{title}</h3>
            <div sx={{
                gridColumnStart: [1, 1, 2],
                justifySelf: 'stretch',
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                {children}
            </div>
        </>
    );
}

function SkillItem({ children, level }) {
    return (
        <div
            sx={{
                padding: '4px 8px',
                borderRadius: '8px',
                borderWidth: '2px',
                borderColor: level === 0 ? 'lightgray' : 'transparent',
                borderStyle: 'solid',
                marginRight: '5px',
                transition: '.2s',
                ':hover': {
                    borderColor: 'darkgray'
                }
            }}
        >
            {children}
        </div>
    );
}

export default {
    Item: SkillItem,
    Section: SkillSection,
    Container: SkillContainer,
};
